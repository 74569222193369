import styled from '@emotion/styled'
import { Slice } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import React, { useEffect, useState } from 'react'
import { useShopStoreIncontext } from '../../context/shopContext'
import fbq from '../../helpers/fbq'
import twq from '../../helpers/twq'
import Afterpay from '../../images/afterpayv2.svg'
import DownArrow from '../../images/arrow_down.svg'
import UpArrow from '../../images/arrow_up.svg'
import Close from '../../images/closev2.svg'
import { trackBeginCheckout, trackViewCart } from '../../lib/ga4'
import LineItem from './LineItem/LineItem'
import Toggle from './Toggle'
import { debounce } from 'lodash'
import Share from '../../images/share.svg'
import axios from 'axios'
import ProgressBar from './ProgressBar'
import QRCode from 'react-qr-code'

const formatterUS = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
})
const formatterUK = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
})
const formatterNZ = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD',
})
const formatterAU = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
})

const formatterCA = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
})

const CartContainer = styled.div`
  padding: 0 32px;
  width: 100%;
  z-index: 99999;
  height: 100%;
  max-width: 100vw;
  background: #252525;
  @media (min-width: 1025px) {
    max-width: 550px;
    width: 550px;
    padding: 0 60px;
  }
  overflow-x: none;
`
const MenuNav = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  right: 32px;
  top: 26px;
`
const MenuNavImage = styled.img`
  cursor: pointer;
`

const CartHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  top: 23px;
  left: 38px;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: -0.34px;
  color: #ffffff;
`

const GreenButton = styled.button`
  padding: 15px 32px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;

  border: none;

  margin: 16px 0;
  margin-bottom: 0;
  width: 100%;

  outline: none;

  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.39px;
  text-align: center;
  color: #000000;
  // border-radius: 27.5px;
  background-color: #ffffff;

  transition: all 0.2s;
  border: solid 1px transparent;
  &:hover {
    background-color: #ffffffcc;
    color: #ffffff;
    border: solid 1px transparent;
  }
  &:active {
    background-color: transparent;
    border: solid 1px rgb(var(--dark-blue-grey));
    color: rgb(var(--dark-blue-grey));
  }
`

const OrDiv = styled.div`
  opacity: 0.3;
  font-family: Objectivity;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 0.23px;
  color: #fff;
`

const AfterPayText = styled.div`
  opacity: 0.3;
  font-family: Objectivity;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: -0.13px;
  text-align: right;
  color: #fff;
  display: flex;
  justify-content: space-between;
`

const ShareText = styled.div`
  opacity: 0.8;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  font-family: Objectivity;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: -0.13px;
  color: #fff;
  display: flex;
  justify-content: center;
  padding-top: 14px;
`

const ExpandIcon = styled.img`
  width: 24px !important;
  height: 24px !important;
`
const CollapsableContent = styled.div`
  background-color: #fff;
  padding: 8px 8px 8px 8px;
  transition: max-height 0.9s linear,
    padding 0.9s linear;
  max-height: 850px;
  overflow: hidden;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.85);
  ${(props) => collapsed(props)};
  & p {
	  margin: 16px 0;
	}
`

const HeaderSection = styled.div`
  cursor: pointer;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  user-select: none;
`
const SectionContainer = styled.div`
  margin-bottom: 8px;
  border-bottom: solid 1px #dee2e2;
`
const GreenButtonInline = styled.button`
  outline: none;
  min-width: 29%;
  height: 45px;
  // border-radius: 34.5px;
  border: #ffffff 1px solid;

  background: white;
  display: block;
  cursor: pointer;
  padding: 15px 12px;
  margin: 0px 0px;
  font-family: Objectivity;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0.66px;
  text-align: center;
  color: #ffffff;

  transition: all 0.2s;
  &:hover {
    background-color: #ffffffcc;
    color: #ffffff;
    border: solid 1px transparent;
  }
  &:active {
    background-color: transparent;
    border: solid 1px rgb(var(--dark-blue-grey));
    color: rgb(var(--dark-blue-grey));
  }
`

const TotalLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: -0.17px;
  color: #fff;
`

const SubTotal = styled.span`
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: -0.36px;
  text-align: right;
  color: #ffffff;
`

const CompleteText = styled.div`
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: rgb(var(--terracota));
  margin-bottom: 16px;
  margin-top: 24px;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -16px;
`
const TextContainer = styled.div`
  width: 70%;
`
const TextTop = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.41px;
  color: rgba(0, 0, 0, 0.85);
`
const TextBottom = styled.div`
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.21px;
  color: rgba(0, 0, 0, 0.85);
`

const ImageContainer = styled.div`
  height: 100%;
  margin: 0 8px;
  width: 130px;
  display: flex;
`

const ColorSelectorContainer = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ColorSelectorCircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 8px 16px;
`

const BottomText = styled.div`
  text-transform: uppercase;
  font-family: Objectivity;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.15px;
  text-align: center;
  color: rgb(var(--terracota));
  margin-top: 24px;

  & p {
    display: inline !important;
  }
`

const IconImage = styled(GatsbyImage)`
  width: 100%;
`

const TitleContainer = styled.span`
  max-width: calc(100% - 24px - 8px);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.66px;
  color: #ffffff;
`

const ShareIcon = styled.img`
  display: inline;
  transform: translateY(-3px);
  opacity: ${(props) => (props.empty ? 0.5 : 1)};
  &:hover {
    cursor: ${(props) => (props.empty ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.empty ? 0.5 : 0.9)};
  }
`

const collapsed = (props) => {
  if (props.collapsed === true) {
    return `
          max-height: 0px;
          padding: 0px 8px 0px 8px;
      `
  }
}

export const ExpandSection = ({ title, body, init, close }) => {
  const initOpen = init === undefined ? true : init
  let [open, setOpen] = useState(initOpen)
  useEffect(() => {
    if (close === false) {
      setOpen(true)
    }
  }, [close])
  return (
    <SectionContainer>
      <HeaderSection onClick={() => setOpen((prev) => !prev)}>
        <TitleContainer>{title}</TitleContainer>
        <ExpandIcon src={open ? UpArrow : DownArrow} />
      </HeaderSection>
      <CollapsableContent collapsed={open}>
        {typeof body === 'string' || body instanceof String ? (
          <div dangerouslySetInnerHTML={{ __html: open ? body : body }} />
        ) : (
          <div> {body} </div>
        )}
      </CollapsableContent>
    </SectionContainer>
  )
}

const loadingList = () => {
  let styles = {}
  for (let $i = 0; $i < 10; $i++) {
    styles['&:nth-of-type(' + $i + ')'] = {
      animationDelay: '' + $i * 0.1 + 's',
    }
  }
  return styles
}
const LoadingElement = styled.div`
  display: inline;
  @keyframes loading01 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  span {
    animation: loading01 1.4s infinite alternate;
    ${loadingList}
  }
`


const CardContainer = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // border-radius: 15px;
  padding: 16px;
  overflow: hidden;

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`

const ColorSelector = ({ selectedColor, setSelectedColor, colors }) => {
  const size = 25
  const Circle = styled.span`
    display: inline-block;
    min-width: ${size}px;
    min-height: ${size}px;
    border-radius: 50%;
    margin: 2px;
    ${(props) => (props.selected ? `border: 1px solid #979797;` : ``)}
    background-color: ${(props) => props.color};
    overflow: hidden;
    cursor: pointer;
  `
  return colors?.map((color, key) => (
    <div key={key}>
      <Circle
        key={key}
        onClick={() => {
          return setSelectedColor(key)
        }}
        color={color.replace('545454', '0c0c0c').replace('4B4E5C', '324058')}
        black={color === '#E6E6E6'}
        selected={key === selectedColor}
      />
    </div>
  ))
}

const LoadingText = () => {
  return (
    <LoadingElement>
      <span>L</span>
      <span>O</span>
      <span>A</span>
      <span>D</span>
      <span>I</span>
      <span>N</span>
      <span>G</span>
    </LoadingElement>
  )
}

const FreightContainer = styled.div`
  margin-top: 12px;
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "Text Button ."
    "Text . ."
    ". . .";

  & .Text { grid-area: Text; }
  & .Button { grid-area: Button; }
  margin-bottom: 12px;
  padding: 8px 0;
  border-bottom: solid 1px #929292;
`

const LineItemContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;

  /* Style the scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
      width: 10px; /* Adjust width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
      background-color: white;
      border-radius: 5px; /* Optional for rounded corners */
  }

  /* Style the scrollbar for Firefox */
  scrollbar-width: thin; /* Or "auto" */
  scrollbar-color: white transparent; /* First value is thumb color, second value is track color */

`

const ScrollableContainer = styled.div`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;             /* Adjust height of the fade */
    background: linear-gradient(transparent, #252525);  /* Change 'white' to match the background of your container or the body */
    pointer-events: none;     /* Ensures the fade doesn't interfere with scrolling */
    @media (max-width: 768px) {
      height: 0px;
    }
  }
`


const CartFooterContainer = styled.div`
  // position: absolute;
  // bottom: 0;
  // left: 60px;
  // right: 60px;
`

const CartContentsContainer = styled.div`
  padding: 16px 0;
  padding-bottom: 0;
  width: 100%;
  height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`

const CopyButton = styled.button`
  display: flex;
  border-radius: 21px;
  padding: 8px 12px;
  margin: 0 12px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
  color: #ffffffee;
  // border-radius: 41.5px;
  border: solid 1px #000;
  background-color: #000;
  font-weight: 400;
  outline: none;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: #ffffff;
  }
  transition: all 0.2s;
  border: solid 1px white;
  ${({background}) => background && `
    background: ${background};
      &:hover {
        background-color: ${background}ee;
        color: #ffffff;
        border: solid 1px transparent;
      }
  `}
  &:hover {
    background-color: rgba(var(--black), 0.8);
    color: #ffffff;
    border: solid 1px transparent;
  }
  &:active {
    background-color: transparent;
    border: solid 1px rgb(var(--black));
    color: rgb(var(--black));
  }

  &:hover {
    background-color: rgba(var(--black), 0.8);
    color: #ffffff;
    border: solid 1px transparent;
  }
`

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)

    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    const debounceResize = debounce(handleResize, 200)

    window.addEventListener('resize', debounceResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', debounceResize);
    }
  }, [])

  return windowWidth
}

const calculatePricing = (checkout) => {
  if (!checkout?.lineItems)
    return {
      sumPrice: 0,
      nonPerSubPriceList: 0,
    }
  let sumPrice = 0
  let nonPerSubPriceList = checkout?.lineItems.edges.map(({ node }) => {
    let lineItem = node
    let netPrice =
      (parseFloat(lineItem.variant?.price?.amount ? lineItem.variant?.price?.amount : '').toFixed(2) || 0) *
      lineItem.quantity
    if (lineItem['discountAllocations'] && lineItem['discountAllocations'].length > 0) {
      lineItem['discountAllocations'].map((discountAllocation) => {
        if (['FREE PERSONALISATION', 'FREE PERSONALIZATION'].includes(discountAllocation['discountApplication']['title']?.toUpperCase())) {
          netPrice -= parseFloat(discountAllocation['allocatedAmount']['amount'])
        }
      })
    }
    if (lineItem.variant.sku.includes('freight') || lineItem.variant.sku.includes('giftcard') || lineItem.variant.sku.includes('AOC-PROV2-COPB28-CRM')) {
      return 0
    }
    return netPrice
  })
  try {
    sumPrice = nonPerSubPriceList.reduce((accumulator, a) => accumulator + a, 0)
  } catch (e) {
    console.log(e)
  }
  return {
    sumPrice,
    nonPerSubPriceList,
  }
}

const mobileCutoff = 750

const Cart = ({ location }) => {
  const windowWidth = useWindowWidth()
  const {
    storeLocale,
    setCartOpen,
    cart,
    checkout,
    checkoutUrl,
    cartLoading,
    protectionEnabled,
    setProtectionEnabled,
    cartOpen,
    currencyCode,
    calculatePrice,
    calculatedTotal,
    mixpanel,
    analytics,
  } = useShopStoreIncontext((state) => {
    return {
      storeLocale: state.storeLocale,
      setCartOpen: state.actions.setCartOpen,
      cart: state.cart,
      checkout: state.checkout,
      checkoutUrl: state.checkoutUrl,
      cartLoading: state.cartLoading,
      protectionEnabled: state.protectionEnabled,
      setProtectionEnabled: state.actions.setProtectionEnabled,
      cartOpen: state.cartOpen,
      currencyCode: state.currencyCode,
      calculatePrice: state.actions.calculatePrice,
      calculatedTotal: state.actions.calculatedTotal,
      mixpanel: state.mixpanel,
      analytics: state.analytics,
    }
  })
  const [shareLoading, setShareLoading] = useState(false)
  const [qrLoading, setQRLoading] = useState(false)
  const [qrCodeId, setQrCodeId] = useState(null)

  const languageLookup = {
    en: 'us',
    'en-AU': 'au',
    'en-NZ': 'nz',
    'en-GB': 'uk',
    'en-CA': 'ca',
  }
  const currentLocale = languageLookup[storeLocale]

  const items = []

  const { sumPrice } = calculatePricing(checkout)

  cart.map(item => {
    items.push({ item_name: item.title, item_id: item.id, item_variant: item?.globalProduct?.[storeLocale]?.title, quantity: item.variantQuantity, price: item.globalProduct[storeLocale]?.price })
  })

  const cartFullPrice = calculatedTotal() - (protectionEnabled ? calculatePrice() : 0)
  let calculatedProtection = (cartFullPrice * 0.025) || 0
  calculatedProtection = Math.max(0, Math.min(50, calculatedProtection))
  const isMobile = windowWidth <= mobileCutoff

  let cartViewed = {
    "Item IDs": items.map(item => item.item_id),
    "Item Types": items.map(item => item.item_variant),
    "Subtotal": Number(checkout?.subtotalPrice?.amount),
    "Currency": currencyCode,
    "Estimated Shipping": calculatedProtection,
    "Estimated Tax": "",
    "Order Total": items.map(item => item.price * item.quantity)?.reduce((a, b) => a + b, 0),
    "Item Names": items.map(item => item.item_name),
  }

  useEffect(() => {
    if (cartOpen) {
      trackViewCart({ items: items, currency: currencyCode, value: Number(checkout?.subtotalPrice?.amount) })
      mixpanel.track('Cart Viewed', cartViewed)
      analytics.track('Cart Viewed', {
        cart_id: checkout.id,
        products: cart.map(lineItem => {
          return {
            product_id: lineItem?.globalProduct?.[storeLocale]?.productId,
            sku: lineItem?.globalProduct?.[storeLocale]?.sku,
            category: lineItem?.product_categories?.[0],
            name: lineItem?.title,
            brand: 'July',
            variant: lineItem?.globalProduct?.[storeLocale]?.title,
            price: lineItem?.globalProduct?.[storeLocale]?.price,
            quantity: lineItem?.variantQuantity,
            url: window.location.href,
            image_url: lineItem?.globalProduct?.[storeLocale]?.image?.gatsbyImageData
                ?.images?.fallback?.src,
          }
        })
      });
    }
  }, [cartOpen])

  const handleCheckout = () => {
    if (cartLoading) return
    if (checkoutUrl === '' || cart.length === 0) return
    const newURL = checkout.webUrl
      .replace('july-travel.myshopify.com', 'checkout.july.com')
      .replace('july-ca.myshopify.com', 'ca-checkout.july.com')
      .replace('july-us.myshopify.com', 'us-checkout.july.com')
      .replace('july-nz-store.myshopify.com', 'checkout-nz.july.com')
      .replace('july-uk.myshopify.com', 'checkout-uk.july.com')

    fbq('InitiateCheckout')
    twq('tw-oe3re-oe3sm')
    try {
      ttq?.track('InitiateCheckout')
    } catch (e) {
      console.log(e)
    }

    let queryData = {}
    // queryData['discount'] = discount
    if (localStorage.getItem('cfclick')) {
      queryData['cfclick'] = localStorage.getItem('cfclick')
    }

    let param = '&'
    var paramsData =
      queryData &&
      Object.entries(queryData)
        .filter(([key, value], i) => !!value)
        .map(([key, value], i) => {
          return value && `${[key]}=${value}&`
        })
    let params = param.concat(paramsData && paramsData.join(''))

    window.location = `${newURL}${params.length > 1 ? params : ''}`
    window.href = checkoutUrl.replace
    trackBeginCheckout({ items: items, currency: currencyCode, value: Number(checkout?.subtotalPrice?.amount) })

    mixpanel.track('View Checkout', cartViewed)
    analytics.track('View Checkout', cartViewed)
  }

  const formatterLookup = {
    'en': formatterUS,
    'en-AU': formatterAU,
    'en-CA': formatterCA,
    'en-GB': formatterUK,
    'en-NZ': formatterNZ
  }
  const formatter = formatterLookup[storeLocale]


  const line_items = cart.map((lineItem, key) => {

    return <LineItem formatter={formatter} key={key} index={key} line_item={lineItem} />
  })

  const shareStoreQR = async () => {
    if (!cart || cart.length === 0) return
    setQRLoading(true)

    const result = await saveCartDetails()
    setQrCodeId(result.data.jsonId)

    setQRLoading(false)
  }

  const saveCartDetails = async () => {
    if (!cart || cart.length === 0) return
    setShareLoading(true)

    const result = await axios.post(
      `${process.env.GATSBY_SERVERLESS_API_URL}/dev/json/create`,
      {
        data: cart,
      }
    )
    setShareLoading(false)

    return result
  }

  const handleCopyCart = async () => {
    if (!cart || cart.length === 0) return

    const result = await saveCartDetails()

    if (result.status === 200) {
      navigator.clipboard.writeText(
        `${location.origin}/${currentLocale}?cartId=${result.data.jsonId}`
      )
      const shareData = {
        title: 'My July Cart',
        text: "Check out the items I've picked for my upcoming adventure!",
        url: `${location.origin}/${currentLocale}?cartId=${result.data.jsonId}`,
      }

      try {
        await navigator.share(shareData)
      } catch (err) {
        console.error(`Error: ${err}`)
      }
    }
  }

  const cart_footer =  (
      <CartFooterContainer>
        {
          calculatedProtection > 0 && (
            <FreightContainer>
              <div className="Text">
                <TotalLine>
                  <span>Premium Package Protection.</span>
                </TotalLine>
                <div style={{ fontSize: 10, paddingRight: 64, lineHeight: 1.4, marginTop: 4, marginBottom: 0, color: '#ffffff99', marginTop: 10 }}>
                  For {!cartLoading ? (
                    formatter.format(calculatedProtection ? calculatedProtection : 0)
                  ) : (
                    <span> ... </span>
                  )} get peace of mind with{" "}
                  <a style={{ textDecoration: 'underline' }} href={storeLocale === 'en' ? 'https://help-us.july.com/en-US/what-if-my-order-is-lost-or-damaged-in-transit-343993'
                    : storeLocale === 'en-NZ' ? 'https://help-nz.july.com/en-GB/what-if-my-order-is-lost-or-damaged-in-transit-344050'
                      : storeLocale === 'en-GB' ? 'https://help-uk.july.com/en-GB/what-if-my-order-is-lost-or-damaged-in-transit-343937'
                        : 'https://help-au.july.com/en-GB/what-is-premium-package-protection-432288'}>Premium Package Protection
                  </a>
                  {" "}in the event your delivery is damaged, stolen or lost during transit. <span>

                  </span>
                </div>
              </div>
              <div className="Button">
                <Toggle value={protectionEnabled} setValue={setProtectionEnabled} />
              </div>
            </FreightContainer>
          )
        }
        <TotalLine>
          <span>Subtotal</span>
          <SubTotal>
            {!cartLoading ? (
              formatter.format(
                checkout?.subtotalPrice?.amount ? checkout?.subtotalPrice?.amount : 0
              )
            ) : (
              <LoadingText />
            )}
          </SubTotal>
        </TotalLine>
        {storeLocale !== 'en-NZ' && storeLocale !== 'en-GB' && (
          <>
            <OrDiv> OR </OrDiv>
            <AfterPayText>
              <img
                style={{
                  objectFit: 'contain',
                  opacity: 0.7,
                  width: 60,
                  height: 23,
                  color: 'white'
                }}
                alt="afterpay"
                src={Afterpay} />
              <span style={{ color: 'white' }}>
                4 x{' '}
                <b>
                  {!cartLoading ? (
                    formatter.format(
                      checkout?.subtotalPrice?.amount ? checkout?.subtotalPrice?.amount / 4 : 0
                    )
                  ) : (
                    <LoadingText />
                  )}
                </b>
              </span>
            </AfterPayText>
          </>
        )}
      <GreenButton onClick={handleCheckout}>
        {cartLoading ? (
          'Loading...'
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 12,
              alignItems: 'center',
              paddingTop: 3
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{marginTop: -5}}
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
            <span> Secure Checkout </span>
          </div>
        )}
      </GreenButton>
      <ShareText onClick={handleCopyCart}>
        {cart?.length !== 0 && shareLoading ? (
          <div className="load" />
        ) : (
          'Share your cart'
        )}
      </ShareText>
    </CartFooterContainer>
  )

  return (
    <CartContainer>
      <MenuNav>
        <MenuNavImage onClick={() => setCartOpen(false)} src={Close} />
      </MenuNav>
      <CartContentsContainer>
        <CartHeader>
          Your Cart.
          <svg
            onClick={shareStoreQR}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeEidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ marginTop: -4, cursor: 'pointer' }}
          >
            <circle cx="12" cy="12" r="1" />
            <circle cx="12" cy="5" r="1" />
            <circle cx="12" cy="19" r="1" />
          </svg>
        </CartHeader>
        {/* <div style={{ position: 'relative', marginBottom: 16 }}>
          <ProgressBar
            itemCount={cart?.filter(lineItem => !lineItem.id.includes('freight') && !lineItem.id.includes('giftcard')).map(val => [val.variantQuantity, val.id])}
            total={sumPrice}
            storeLocale={storeLocale}
          />
        </div> */}
        {qrCodeId && (
          <div
            style={{
              position: 'absolute',
              inset: 0,
              background: 'white',
              padding: 10,
              borderRadius: 10,
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              zIndex: 99999,
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={{padding: '16px 16px', fontSize: 16, fontWeight: 500}}>Scan this QR code in store to checkout!</div>
            <QRCode value={qrCodeId} />
            <div style={{ position: 'absolute', bottom: 0, right: 0, left: 0, background: 'black', color: 'white', textAlign: 'center', fontWeight: 600, padding: 32, cursor: 'pointer' }} onClick={() => setQrCodeId(null)}>
              CLOSE
            </div>
          </div>
        )}
        <ScrollableContainer>
          <LineItemContainer>
            {line_items}
            <Slice alias="upsell-module" />
            { isMobile && cart_footer }
          </LineItemContainer>
        </ScrollableContainer>
        { !isMobile && cart_footer }
      </CartContentsContainer>

    </CartContainer>
  )
}

export default Cart
